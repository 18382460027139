import React from 'react';
import moment from 'moment/moment';
import { Link } from 'gatsby';
//import ViewCounter from './ViewCounter';

const PostEntryMeta = ({ post, location }) => (
  <ul className="entry-meta flex items-center medium header-font gray">
    <li className="meta-date posted-on">
      <Link to={`/${post.uri}/`}>
        <time className="entry-date" dateTime={post.date}>
          {moment(post.date).format(`MMM D, YYYY`)}
        </time>
      </Link>
    </li>
    <li className="meta-author byline">
      <span>
        By&nbsp;
        <span className="author">
          <Link to={`/author/${post.author.node.slug}/`}>
            {`${post.author.node.name}`}
          </Link>
        </span>
      </span>
    </li>
    {/*<ViewCounter id={post.uri} location={location} />*/}
  </ul>
);

export default PostEntryMeta;
