import React from "react"
import { Link } from "gatsby"

const PostTitle = ({ post, location, titleClass }) => (
  <>
    {location === "single" ? (
      <h1 className={titleClass} itemProp="headline" dangerouslySetInnerHTML={{ __html: post.title }} />
    ) : (
      <h2 className={titleClass} itemProp="headline">
        <Link to={`${post.uri}`} dangerouslySetInnerHTML={{ __html: post.title }} />
      </h2>
    )}
  </>
)

export default PostTitle